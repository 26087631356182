// $gray: #495057;

// .tooltip__wrapper {
// 	position: relative;
// 	display: inline-block;
// }

// .tooltip__text {
// 	font-size: 14px;
// 	line-height: 1;
// 	display: block;
// 	font-family: "Calibri";
// 	text-align: center;
// 	// max-height: 52px;
// 	min-width: 24em;
// 	max-width: 30em;
// 	white-space: normal;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	padding: 0.5rem;
// 	border-radius: 0.3rem;
// 	box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
// 	background: #495057;
// 	color: #fff;
// 	z-index: 1000;

// 	&::after {
// 		position: absolute;
// 		content: "";
// 		border: 5px solid transparent;
// 		z-index: 1001;
// 	}
// }

// .tooltip--top {
// 	bottom: 100%;
// 	border-bottom-width: 0;
// 	border-top-color: $gray;
// 	left: 50%;
// 	transform: translate(-50%, -0.5em);

// 	&::after {
// 		bottom: calc(100% + 5px);
// 		left: 50%;
// 		transform: translate(-50%, -0.5em);
// 	}
// }

// .tooltip--bottom {
// 	top: 105%;
// 	border-top-width: 0;
// 	border-bottom-color: $gray;
// 	left: 50%;
// 	transform: translate(-50%, 0.5em);

// 	&::after {
// 		top: calc(105% + 5px);
// 		left: 50%;
// 		transform: translate(-50%, 0.5em);
// 	}
// }

// .tooltip--left {
// 	top: 50%;
// 	border-right-width: 0;
// 	border-left-color: $gray;
// 	left: calc(0em - 5px);
// 	transform: translate(-0.5em, -50%);

// 	&::after {
// 		top: 50%;
// 		right: calc(100% + 5px);
// 		transform: translate(-0.5em, -50%);
// 	}
// }

// .tooltip--right {
// 	top: 50%;
// 	border-left-width: 0;
// 	border-right-color: $gray;
// 	right: calc(0em - 5px);
// 	transform: translate(0.5em, -50%);

// 	&::after {
// 		top: 50%;
// 		left: calc(100% + 5px);
// 		transform: translate(0.5em, -50%);
// 	}
// }

$gray: #495057;

/* Input container styling */
.input-container {
	position: relative;
	display: inline-block;
}

/* Tooltip styling */
.input-tooltip {
	position: absolute;
	bottom: 100%;
	right: -1%;
	// transform: translateX(-50%);
	background-color: rgba(73,80,87, 0.9);
	color: #fff;
	padding: 5px;
	border-radius: 4px;
	min-width: 10em;
	max-width: 30em;
	font-size: 14px;
	width:15em;
	white-space: normal;
	font-size: 12px;
}

.input-tooltip-arrow {
	position: absolute;
	top: 100%;
	right: 10%;
	transform: translateX(-50%);
	border: 5px solid transparent;
	border-top-color: $gray;
	pointer-events: none;
}

@media only screen and (max-width: 360px) {
	.input-tooltip {
		min-width: 100%;
		max-width: inherit;
	}
}
