$darkBlue: #0071eb;
$red: #d04343;
$orange: #ac631b;
$black: #3c3c43;
$white: #fff;
$grey: #efefef;
$pink: #e2088b;

$primary: $darkBlue;
$danger: $red;
$success: #2d8659;
$warning: #c97420;
$secondary: $pink;
$light: #f9fafb;
$dark: $black;

$focusPrimary: #002c5c;
$focusOrange: #3a2909;
$focusSuccess: #103225;
$focusPink: #541235;
$focusWarning: #7f5206;

$pressedPrimary: #1941a6;
$pressedOrange: #934800;
$pressedSuccess: #00582f;
$pressedPink: #93065b;
$pressedWarning: #7f5206;

$modal-backdrop-opacity: 0.8;

button {
	font-family: Calibri;

	&[type="submit"] {
		.material-icons-outlined {
			font-size: 24px;
		}
	}
}

.btn-link {
	padding: 0;
	border-radius: 8px;
}

.btn-primary {
	color: $white;
	background-color: $primary;
	border: 2px solid $primary;

	// &:focus-visible {
	// 	background-color: $focusPrimary;
	// 	border-color: $focusPrimary;
	// }

	&:hover,
	&:focus:hover {
		background-image: none;
		background-color: #fff;
		border-color: $pressedPrimary;
		color: #3c3c43;
	}

	&.in-progress {
		background-color: #93c4f9;
		border-color: #93c4f9;
		color: #ffffff;
	}

	&:focus-visible,
	&:focus {
		background-color: $primary;
		border-color: $primary;
		color: #ffffff;
	}

	&.no-focus-ring {
		&:focus-visible,
		&:focus {
			background-color: $focusPrimary;
			border-color: $focusPrimary;
			color: #ffffff;
		}

		&:active,
		&:active:focus {
			background-color: $pressedPrimary;
			border-color: $pressedPrimary;
			color: #ffffff;
		}
	}

	&:active,
	&:active:focus {
		background-color: $pressedPrimary;
		border-color: $pressedPrimary;
		color: #ffffff;
	}

	&.disabled {
		&:focus {
			box-shadow: none;
		}
	}
}

.btn-success {
	color: $white;
	background-color: $success;
	border: 2px solid $success;

	// &:focus-visible {
	// 	background-color: $focusSuccess;
	// 	border-color: $focusSuccess;
	// }

	&:hover,
	&:focus:hover {
		background-image: none;
		background-color: #fff;
		border-color: $pressedSuccess;
		color: #3c3c43;
	}

	&.in-progress {
		background-color: #a5c9b7;
		border-color: #a5c9b7;
		color: #ffffff;
	}

	&:focus-visible,
	&:focus {
		background-color: $success;
		border-color: $success;
		color: #ffffff;
	}

	&.no-focus-ring {
		&:focus-visible,
		&:focus,
		&:active:focus {
			background-color: $focusSuccess;
			border-color: $focusSuccess;
			color: #ffffff;
		}

		&:active,
		&:active:focus {
			background-color: $pressedSuccess;
			border-color: $pressedSuccess;
			color: #ffffff;
		}
	}

	&:active,
	&:active:focus {
		background-color: $pressedSuccess;
		border-color: $pressedSuccess;
		color: #ffffff;
	}
}

.btn-warning {
	color: $white;
	background-color: $warning;
	border: 2px solid $warning;

	// &:focus-visible {
	// 	background-color: $focusWarning;
	// 	border-color: $focusWarning;
	// }

	&:hover,
	&:focus:hover {
		background-image: none;
		background-color: #fff;
		border-color: #e3c1a0;
		color: #3c3c43;
	}

	&:focus-visible,
	&:focus {
		background-color: $warning;
		border-color: $warning;
		color: #ffffff;
	}

	&.no-focus-ring {
		&:focus-visible,
		&:focus {
			background-color: $focusWarning;
			border-color: $focusWarning;
			color: #ffffff;
		}

		&:active,
		&:active:focus {
			background-color: #934800;
			border-color: #934800;
			color: #ffffff;
		}
	}

	&:active,
	&:active:focus {
		background-color: #934800;
		border-color: #934800;
		color: #ffffff;
	}
}

.btn-orange {
	color: $white;
	background-color: $orange;
	border: 2px solid $orange;

	// &:focus-visible {
	// 	background-color: $focusWarning;
	// 	border-color: $focusWarning;
	// }

	&:hover,
	&:focus:hover {
		background-image: none;
		background-color: #fff;
		border-color: #934800;
		color: #3c3c43;
	}

	&:focus-visible,
	&:focus {
		background-color: $orange;
		border-color: $orange;
		color: #ffffff;
	}

	&.no-focus-ring {
		&:focus-visible,
		&:focus {
			background-color: $focusOrange;
			border-color: $focusOrange;
			color: #ffffff;
		}

		&:active,
		&:active:focus {
			background-color: $pressedOrange;
			border-color: $pressedOrange;
			color: #ffffff;
		}
	}

	&:active,
	&:active:focus {
		background-color: $pressedOrange;
		border-color: $pressedOrange;
		color: #ffffff;
	}
}

.btn-pink {
	color: $white;
	background-color: $pink;
	border: 2px solid $pink;

	// &:focus-visible {
	// 	background-color: $focusSecondary;
	// 	border-color: $focusSecondary;
	// }

	&:hover,
	&:focus:hover {
		background-image: none;
		background-color: #fff;
		border-color: #93065b;
		color: #3c3c43;
	}

	&:focus-visible,
	&:focus {
		background-color: $pink;
		border-color: $pink;
		color: #ffffff;
	}

	&.no-focus-ring {
		&:focus-visible,
		&:focus {
			background-color: $focusPink;
			border-color: $focusPink;
			color: #ffffff;
		}

		&:active,
		&:active:focus {
			background-color: $pressedPink;
			border-color: $pressedPink;
			color: #ffffff;
		}
	}

	&:active,
	&:active:focus {
		background-color: $pressedPink;
		border-color: $pressedPink;
		color: #ffffff;
	}
}

.btn-outline-light {
	color: $black;
	background: none;
	border: 0;
	border-radius: 8px;

	&.iconOnly {
		padding: 0;
	}
}

.btn-outline-dark {
	padding: 11px 8px;
	border: 1px solid #767676;
	color: $black;

	&.w-icon {
		padding: 7px 8px;
	}

	.round-icon {
		display: inline-block;
		width: 40px;
		height: 40px;
		border: 2px solid $black;
		border-radius: 32px;

		img {
			width: 24px;
			height: auto;
		}

		.material-icons-outlined {
			font-size: 24px;
		}
	}

	.button-with-left-icon {
		width: calc(100% - 80px);
	}

	&:hover,
	&:focus-visible {
		background-color: $black;
		color: $white;

		.round-icon {
			border-color: $white;
		}
	}
}

.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	// padding: 1.438rem 0.75rem;
	padding: 0.938rem 0.75rem;
	border-radius: 0;
	font-weight: bold;
	line-height: 24px;

	.disabled-text-message {
		font-weight: 400;
		font-size: 10px;
		display: block;
	}

	&.h-56 {
		// padding: 15px 0.75rem;
		padding: 11px 0.75rem;
	}

	&:disabled,
	&.disabled {
		background-color: $grey;
		color: rgba(60, 60, 67, 0.4);
		border-color: $grey;
		opacity: 1;
		cursor: not-allowed;
		pointer-events: all;

		&:hover {
			background-color: $grey;
			color: rgba(60, 60, 67, 0.4);
			border-color: $grey;
		}
	}

	&:active {
		&:focus {
			box-shadow: none;
		}
	}

	&:focus-visible {
		outline: 4px solid #1941a6;
		outline-offset: 3px;
		box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
	}

	&.no-focus-ring {
		&:focus-visible {
			outline: none;
			outline-offset: 3px;
			box-shadow: none;
		}
	}
}

.modal {
	button {
		padding: 6px 12px;
		line-height: inherit;
		border-radius: 8px;
	}
}
