.modal {
    .warning-icon {
        position: absolute;
        left: 44%;
        top: -20px;
        width: 50px;
        max-width: 100%;
        height: auto;
    }

    .modal-header {
        padding: 16px;
    }
}

.warning-modal {
    .modal-header {
        background: #e69b23;
        color: #222;

        .close {
            color: #222;
        }
    }
 }

.primary-modal {
    .modal-header {
        background: #23356d;
        color: #222;

        .close {
            color: #222;
        }
    }
}

.danger-modal {
    .modal-header {
        background: #ca3333;
        color: #fff;

        .close {
            color: #fff;
        }
    }
}

.default-modal {
    .modal-header {
        background: linear-gradient(to bottom, #ffffff, #eff2f6);
        color: #222;

        .close {
            color: #222;
        }
    }
}

.warning-modal,
.primary-modal,
.danger-modal,
.default-modal {
    .modal-dialog {
        width: 600px;
    }
    
    p {
        font-size: 1.111rem;
    }
}