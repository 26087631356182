$darkBlue: #3b81f6;
$red: #ef4444;
$orange: #f59e0b;
$black: #3c3c43;
$white: #fff;
$grey: #efefef;
$pink: #f50996;

$primary: $darkBlue;
$danger: $red;
$success: #34d399;
$warning: $orange;
$secondary: $pink;
$light: #f9fafb;
$dark: $black;

$focusSecondary: #6a0642;
$focusWarning: #7f5206;
$focusSuccess: #187251;
$focusPrimary: #043486;

$modal-backdrop-opacity: 0.8;

fieldset:disabled .btn {
	opacity: 1;
}

// Form
form {
	label {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 0.25rem !important;
		color: $black;
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 1;

		.material-icons-outlined {
			color: rgba(60, 60, 67, 0.88);
			font-size: 24px;
		}
	}

	.invalid-feedback {
		background: $red;
		color: $white;
		border-radius: 8px;
		padding: 8px;
		font-size: 16px;
		line-height: 24px;
		margin-top: 0.5rem;

		.material-icons-outlined {
			font-size: 20px;
			margin-right: 5px;
		}

		@media only screen and (max-width: 768px) {
			font-size: 12px;
			line-height: 16px;
			padding: 6px 10px;
			border-radius: 4px;

			.material-icons-outlined {
				font-size: 16px;
				margin-right: 4px;
			}
		}
	}

	.form-check-label {
		font-weight: normal;
		margin: 0;
	}

	.passwordRequirements {
		.row {
			font-size: 12px;
			line-height: 16px;
			color: rgba(60, 60, 67, 0.56);

			& > div {
				padding: 0;
			}
		}

		.material-icons-outlined,
		.material-icons {
			font-size: 16px;
			margin-right: 5px;
		}

		label {
			font-size: 12px;
			font-weight: bold;
			line-height: 16px;
			justify-content: inherit;
			margin-bottom: 0 !important;

			span {
				margin-left: 5px;
			}
		}

		.col-6.d-flex {
			margin-top: 8px;
		}

		.progress {
			margin-top: 4px;
			height: 3px;
			border-radius: 32px;
		}
	}
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
// 	-webkit-box-shadow: 0 0 0 30px white inset !important;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-background-clip: text;
	-webkit-box-shadow: 0 0 0 30px white inset;

	&:focus-visible {
		outline: 4px solid #1941a6;
		outline-offset: 3px;
		box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
		background: #fff;
		background-color: #fff;
	}
}

.form-control {
	&:focus,
	&:active:focus {
		border: 1px solid #3b82f6;
		box-shadow: none;
	}

	&:focus-visible {
		outline: 4px solid #1941a6;
		outline-offset: 3px;
		box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
	}
}

.form-control {
	border-radius: 0.5rem;
	// padding: 15px;
	padding: 11px 15px;
	line-height: 24px;
	border-color: #767676;
}

/* Checkbox */
.checkbox-custom input[type="checkbox"] {
	position: relative;
	margin: 0;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;

	width: 24px;
	height: 24px;
	// border-radius: 100%;
	background-color: #ffffff;
	border: 1px solid #767676;
	box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
	background-size: 0 0;
	margin-right: 8px;

	&:checked {
		background: $darkBlue;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
		box-shadow: 0px 2px 4px rgba(60, 60, 67, 0.24);
		background-size: 100% 100%;
		border-color: $darkBlue;
	}

	&:focus-visible {
		outline: 4px solid #1941a6;
		outline-offset: 3px;
		box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
	}
}
