
#content {
	width: 100%;
	min-height: 100%;
	background: rgba(219, 234, 254, 0.16);
	padding-top: 72px;

	&.content-isMobile {
		padding-top: 56px;
	}

	.box-shadow {
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
		height: 100%;
		max-height: 100%;
		// max-height: 613px;
		// overflow: auto;
		scroll-behavior: smooth;

		@media only screen and (min-device-width: 481px) and (orientation: portrait) {
			height: auto;
		}
	}

	.layout-container,
	.layout-container-fluid {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-right: var(--bs-gutter-x, 0.75rem);
		padding-left: var(--bs-gutter-x, 0.75rem);
	}

	.layout-container {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	// .layout-container-fluid {
	// 	height: calc(100vh - 72px - 24px - 24px);
	// }

	@media (min-width: 768px) {
		.layout-container {
			max-width: 768px;
		}
	}
}
