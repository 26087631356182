
.topbar-container,
.topbar-container-fluid {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
}

.topbar-container-fluid {
	height: 72px;
}

.navbar {
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}

.navbar-brand {
	img {
		width: 190px;
		height: auto;
	}
}

@media (min-width: 768px) {
	.topbar-container {
		max-width: 768px;
	}
}
