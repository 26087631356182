// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

$darkBlue: #3b81f6;
$red: #ef4444;
$orange: #f59e0b;
$black: #3c3c43;
$white: #fff;
$grey: #efefef;
$pink: #f50996;

$primary: $darkBlue;
$danger: $red;
$success: #8bdab3;
$warning: $orange;
$secondary: $pink;
$light: #f9fafb;
$dark: $black;

$focusSecondary: #6a0642;
$focusWarning: #7f5206;
$focusSuccess: #187251;
$focusPrimary: #043486;

$modal-backdrop-opacity: 0.8;

// 3. Include remainder of required Bootstrap stylesheets
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";
// 4. Include any optional Bootstrap components as you like
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/offcanvas";

// 5. Add additional custom code here
@import "node_modules/bootstrap/scss/helpers";
@import "node_modules/bootstrap/scss/utilities/api";

@import "../components/tooltip";

@font-face {
	font-family: "Calibri";
	src: url("/fonts/Calibri.ttf");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Calibri";
	src: url("/fonts/Calibrib.ttf");
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: "Calibri";
	src: url("/fonts/calibrii.ttf");
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: "Calibri";
	src: url("/fonts/calibriz.ttf");
	font-weight: bold;
	font-style: italic;
}

html,
body,
#app {
	height: 100%;
	min-height: 100%;
}

body {
	font-family: Calibri;
	color: $black;
}

label {
	font-size: 10px;
	font-weight: bold;
}

a,
.btn-link {
	text-decoration: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}

	&:focus-visible {
		outline: 4px solid #1941a6;
		outline-offset: 3px;
		box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
	}
}

a {
	color: $darkBlue;
}

img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.material-icons-outlined {
	font-size: 24px;
	display: block;
}

.height48 {
	height: 48px;
}

.height56 {
	height: 56px;
}

.container {
	height: calc(100% - 40px);
	padding: 0;
}

.pageIcons {
	.material-icons-outlined {
		font-size: 40px;
	}
}

.container-fluid {
	width: 500px;
	height: calc(100vh - 40px);
	height: 100%;
}

.authPage {
	// height: 100%;
	// min-height: calc(100vh - 72px - 24px - 24px);

	// @media only screen and (min-device-width: 481px) and (orientation: portrait) {
	// 	min-height: auto;
	// }

	.header {
		margin: 0 auto;
		margin-bottom: 5rem;

		h1 {
			font-size: 36px;
			line-height: 44px;
			font-weight: bold;
		}
	}

	h2 {
		font-size: 1.75rem;
		line-height: 1.143;
	}

	.pageIcons {
		width: 80px;
		height: 80px;
		color: rgba(60, 60, 67, 0.24);
		border: 4px solid rgba(60, 60, 67, 0.16);
		border-radius: 50%;

		i {
			font-size: 40px;
		}
	}

	.fa-arrow-left {
		color: #3c3c43;
		font-size: 24px;
	}
}

.header-icon {
	img {
		width: 56px;
	}

	.material-icons-outlined {
		font-size: 40px;
		color: rgba(60, 60, 67, 0.24);
	}
}

.announcement-modal {
	.pageIcons {
		width: 80px;
		height: 80px;
		color: rgba(60, 60, 67, 0.24);
		border: 4px solid rgba(60, 60, 67, 0.16);
		border-radius: 50%;

		i {
			font-size: 40px;
		}
	}
}

.success-label {
	font-size: 12px;
	.material-icons-outlined {
		font-size: 13px;
		margin-right: 5px;
	}
}

.transparentBackground {
	filter: blur(2px);
	height: 30px;
	background: linear-gradient(0deg, white, transparent);
	opacity: 0.8;
}

.collapse-label {
	&.collapsed {
		& > span:nth-child(1) {
			display: inline-block;
		}

		& > span:nth-child(2) {
			display: none;
		}
	}

	&:not(.collapsed) {
		& > span:nth-child(1) {
			display: none;
		}

		& > span:nth-child(2) {
			display: inline-block;
		}
	}
}

.modal-dialog {
	width: 375px;

	.modal-content {
		border: 0;
	}

	.modal-header {
		height: 72px;
		padding: 1.5rem 1.875rem;
	}

	.modal-body {
		padding: 1.5rem 1.875rem;
	}
}

.isMobileModal {
	.modal-dialog {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		max-width: 100% !important;
		margin: 0;
		transform: translateY(100%) !important;
		.modal-content {
			border-radius: 0;
		}
	}

	&.show {
		overflow: hidden;
		.modal-dialog {
			transform: translateY(0%) !important;
			min-height: 80px;
		}
	}

	.dialog-scrollable {
		height: 100%;
	}

	.modal-content {
		position: absolute;
		bottom: 0;
	}

	.modal-header {
		height: auto;
		padding: 1.5rem 1rem;
	}

	.modal-body {
		padding: 0 1rem 2.5rem 1rem;
	}
}

.content-top-bar {
	width: 100%;
	height: 72px;
	z-index: 10;

	.material-icons-outlined {
		font-size: 24px;
	}

	&.top-bar-fixed {
		position: fixed;
		width: 100%;
		top: 0;
		padding: 10px;
		height: 56px;
		z-index: 10;
	}

	&.position-sticky {
		top: calc(72px + 58px); // with warning alert
	}
}

.noAlertSign {
	.content-top-bar {
		&.position-sticky {
			top: 72px; // without warning alert
		}
	}
}

.ps-025 {
	padding-left: 0.25px !important;
}

.pe-025 {
	padding-right: 0.25px !important;
}

.pt-30 {
	padding-top: 30px;
}

.pt-40 {
	padding-top: 40px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pt-72 {
	padding-top: 72px;
}

.pb-78 {
	padding-bottom: 78px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pt-82 {
	padding-top: 82px;
}

.pb-84 {
	padding-bottom: 84px;
}

.pb-86 {
	padding-bottom: 86px;
}

.pb-102 {
	padding-bottom: 102px;
}

.pb-104 {
	padding-bottom: 104px;
}

.pb-118 {
	padding-bottom: 118px;
}

.pb-120 {
	padding-bottom: 120px;
}

.pb-130 {
	padding-bottom: 130px;
}

.pb-163 {
	padding-bottom: 163px;
}

.pb-176 {
	padding-bottom: 176px;
}

.auth-page-content {
	padding-top: 70px;
}

.mobile-view-content {
	padding-top: 72px;
}

// .cursor-pointer,
// a:hover,
// div[data-bs-toggle="modal"] {
// 	cursor: pointer;
// }

.custom-btn-gradient {
	&.bg-gradient {
		// height: 62px;
		height: 50px;
		background-image: linear-gradient(
			-180deg,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 1)
		) !important;
	}
}

.height-minus-top-bar {
	height: calc(100% - 72px);
}

.min-h-100 {
	min-height: 100%;
}

.mt-2px {
	margin-top: 2px;
}

.mt-40 {
	margin-top: 40px;
}

.alert-danger {
	border: none;
	border-radius: 0;
	background: $danger;
	color: $white;
	padding: 16px 24px;
	font-size: 14px;
	border-radius: 4px;
}

.alert-success {
	background: $success;
	color: $black;

	.material-icons-round {
		color: #00582f;
	}
}

.alert-dark {
	background: #f5f5f5;
	border-color: #f5f5f5;
	color: #424242;
}

.terms-of-use {
	.header {
		margin-bottom: 0;
	}
}

.button-bottom {
	box-shadow: 0 -50px 50px 0px $white;
}

ul {
	li {
		color: $darkBlue;
	}
}

// .course-info {
// 	h2,
// 	h3 {
// 		font-weight: bold;
// 	}

// 	h2 {
// 		font-size: 20px;
// 		line-height: 28px;
// 		margin-bottom: 8px;
// 	}

// 	h3 {
// 		font-size: 12px;
// 	}

// 	p {
// 		font-size: 14px;
// 		margin-bottom: 8px;
// 	}

// 	.course-info-collapse {
// 		label {
// 			font-size: 12px;
// 		}
// 	}

// 	.form-label {
// 		font-size: 12px;
// 		margin-bottom: 0.25rem !important;
// 		line-height: 1.334;
// 	}
// }

.initials-img {
	width: 100%;
	height: 100%;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.8em;
}

// margin
.ms-8px {
	margin-left: 8px;
}

.me-8px {
	margin-right: 8px;
}

.mb-5rem {
	margin-bottom: 5rem;
}

// z-index
.z-index-1 {
	z-index: 1;
}

.cursor-pointer {
	cursor: pointer;
}

.top--3px {
	top: -3px;
}

.top-33px {
	top: 33px;
}

.end-33px {
	right: 33px;
}

.visible-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.visible-hidden:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
}

.form-label {
	width: fit-content;
}

.accordion-item {
	border-radius: 8px;
	border: 1px solid #767676;

	.accordion-button {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;

		&.collapsed {
			border-radius: 8px;
		}

		&::after {
			width: 1.8rem;
			height: 1.8rem;
			background-size: 1.8rem;
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="%23222222"><path d="M480-360 280-560h400L480-360Z"/></svg>');
		}

		&:not(.collapsed)::after {
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="%231668e5"><path d="M480-360 280-560h400L480-360Z"/></svg>');
		}
	}

	&:not(:first-of-type) {
		border-top: 1px solid #767676;
	}

	&:first-of-type {
		border-radius: 8px;

		.accordion-button {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;

			&.collapsed {
				border-radius: 8px;
			}
		}
	}
}

.position-inherit {
	position: inherit;
}