html,
body {
	font-size: 18px;
	font-size: 1rem;
}

h1 {
	// h1 33px 1.83rem
	font-size: 1.83rem;
	font-weight: bold;
}

h2 {
	// h2 29px 1.61rem
	font-size: 1.61rem;
	font-weight: bold;
}

h3 {
	// h3 26px 1.44rem
	font-size: 1.44rem;
	font-weight: bold;
}

h4 {
	// h4 24px 1.33rem
	font-size: 1.33rem;
}

h5 {
	// h5 20px 1.11rem
	font-size: 1.11rem;
}

p {
	// p 18px 1rem
	font-size: 1rem;
}

label {
	// label 16px 0.88rem
	font-size: 0.88rem;
}

.fs-20px {
	font-size: 1.11rem;
}

.text-dark-grey {
	color: #767676;
}

.text-black {
	color: #222;
}
