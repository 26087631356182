
.vue-pincode-input {
	outline: none;
	margin-left: 4px;
	margin-right: 4px;
	padding: 6px;
	width: 15%;
	text-align: center;
	font-size: 28px;
	background: #fff;
	border: none;
	border-radius: 4px;
	line-height: 0.8;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
	border: 1px solid rgba(0, 0, 0, 0.2);

	&:nth-child(3n + 3) {
		margin-right: 10px;
	}

	&:nth-child(4) {
		margin-left: 10px;
	}

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&:focus {
		border: 1px solid #3b82f6;
		box-shadow: none;
	}

	&:focus-visible {
		border: 1px solid #3b82f6;
		outline: 4px solid #1941a6;
		outline-offset: 3px;
		box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
	}
}
